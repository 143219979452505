/* -------------- Animation -------------- */

#animation {
  display: inline-block;
  position: absolute;
  bottom: 0;
  margin-left: 20px;
}



/* -------------- Frame -------------- */

.showRoll #frame {
  border-color: hsl(0,0%,70%);
}

#frame {
  width: 50px;
  height: 72px;
  border: 1px solid transparent;
  background: url(https://s.cdpn.io/79/sprite-steps.png) no-repeat left top;
  animation: frame-animation 1s steps(10) infinite;    
}

@keyframes frame-animation { 0% { background-position: 0px 0; } 100% { background-position: -500px 0; } }



/* -------------- Roll -------------- */

.showRoll #roll {
  opacity: .2;
}

#roll {
  opacity: 0;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 500px;
  height: 72px;
  border: 1px solid hsl(0,0%,70%);
  background: hsl(0,0%,100%) url(https://s.cdpn.io/79/sprite-steps.png) no-repeat left top;
  transition: opacity .3s linear;
  animation:  roll-animation 1s steps(10) infinite;
}

@keyframes roll-animation { 
  0% { transform: translateX(0); } 
  100% { transform:  translateX(-500px); } 
}

.video-responsive {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;
  height: 90vh;
}
.video-responsive iframe {
  width: 100%;
  height: 100%;
}

