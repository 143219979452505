@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .square {
    aspect-ratio: 1 / 1;
  }
}

.reveal {
  color:#1f2937;
  background: linear-gradient(#1f2937 0 0), linear-gradient(#1f2937 0 0);
  background-size:0 100%;
  background-position:0 0;
  -webkit-background-clip:padding-box,text;
          background-clip:padding-box,text;
  background-repeat:no-repeat;
  -webkit-box-decoration-break: clone;
          box-decoration-break: clone;
  animation: 
   t 1.2s  .5s both,
   b 1.2s 1.3s both;
}
@keyframes t{
  to {
    background-size:150% 100%;
    color: #fff;
  }
}
@keyframes b{
  to {
    background-position:-200% 0,0 0;
    color: #1f2937;
  }
}